<script setup lang="ts">
  import { HeartIcon } from '@heroicons/vue/24/outline'
  import AuthenticationService from '~/services/AuthenticationService'
  import FavoriteService from '~/services/FavoriteService'
  import { useModalStore } from '~/stores/modalStore'
  import GenericSuccessModal from '~/components/modals/GenericSuccessModal.vue'
  import GenericErrorModal from '~/components/modals/GenericErrorModal.vue'

  const props = defineProps({
    spaceId: {
      type: Number,
      required: false,
      default: null,
    },
    providerId: {
      type: Number,
      required: false,
      default: null,
    },
  })

  const { spaceId, providerId } = toRefs(props)
  
  // Determine entity type based on which ID is provided
  const entityType = computed(() => {
    if (spaceId.value) return 'SPACE'
    if (providerId.value) return 'PROVIDER'
    return null
  })
  
  // Get entity ID based on which one is provided
  const entityId = computed(() => {
    return spaceId.value || providerId.value
  })
  
  // Set appropriate success message based on entity type
  const favoriteSuccessMessage = computed(() => {
    return entityType.value === 'PROVIDER' 
      ? 'Fornecedor adicionado aos favoritos com sucesso.'
      : 'Espaço adicionado aos favoritos com sucesso.'
  })

  const handleAddToUserFavorites = async () => {
    if (!entityId.value || !entityType.value) {
      console.error('Entity ID or type is missing')
      return
    }
    
    if (AuthenticationService.isAuthenticated()) {
      try {
        await FavoriteService.addFavorite(entityId.value, entityType.value)
        useModalStore().showSuccessModal = true
        useModalStore().successMessage = favoriteSuccessMessage.value
      } catch (error: any) {
        if (error.message) {
          useModalStore().showErrorModal = true
          useModalStore().errorMessage = error.message ?? ''
        }
      }
    } else {
      useModalStore().showUserNotLoggedModal = true
    }
  }
</script>

<template>
  <div class="absolute right-0 top-0 flex">
    <GenericSuccessModal />
    <GenericErrorModal />
    <LazyModalsUserNotLoggedModal />
    <div class="mx-1 mt-1 inline-flex">
      <span
        class="hint--left hint--no-arrow hint--rounded z-10 text-xs text-gray-100"
        aria-label="Adicionar aos favoritos">
        <HeartIcon
          class="h-8 w-8 cursor-pointer hover:text-red-600"
          title="Adicionar aos favoritos"
          @click.prevent="handleAddToUserFavorites" />
      </span>
    </div>
  </div>
</template>
